<template>
    <div class="main-categories container">
        <h1 class="main-categories__heading heading">Категории Duty Free Flowers</h1>

        <h1 class="main-categories__heading main-categories__heading-mob heading">Категории <br> Duty Free Flowers</h1>

        <div class="main-categories__inner categories" v-dragscroll>

            <!-- <div class="categories__item item" @click="redirectToCatalog('8 Марта', '')">
                <img class="item__img" src="../images/main-categories/8-cat.png">

                <div class="item__content">
                    <h1 class="item__name">8 марта</h1>
                    <span class="item__show">смотреть</span>
                </div>
                <p class="item__content_march-promo">Промокод -15% "ВЕСНА"</p>
            </div> -->

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Все', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='Все'}">
                <img class="item__img" src="../images/main-categories/all.png">

                <div class="item__content">
                    <h1 class="item__name">Все цветы</h1>
                </div>
            </div>

            <!-- <div 
                class="categories__item item" 
                @click="redirectToCatalog('День Матери', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='День Матери'}">
                <img class="item__img" src="../images/main-banners/mother-ban.png">

                <div class="item__content">
                    <h1 class="item__name">День матери</h1>
                    <p class="item__price">от 4 245 рублей</p>
                </div>
            </div> -->

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Авторские букеты', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='Авторские букеты'}">
                <img class="item__img" src="../images/main-categories/auth.png">

                <div class="item__content">
                    <h1 class="item__name">Авторские букеты</h1>
                    <p class="item__price">от 4 245 рублей</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Цветочные композиции', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='Цветочные композиции'}">
                <img class="item__img" src="../images/main-categories/comp.png">
                
                <div class="item__content">
                    <h1 class="item__name">цветочные композиции</h1>
                    <p class="item__price">от 4 180 рублей</p>
                </div>
            </div>

            <div 
                @click="redirectToCatalog('Букеты', '')"
                class="categories__item item"
                :class="{activeCategory: SELECTED_CATEGORY=='Букеты'}">
                <img class="item__img" src="../images/main-categories/buq.png">

                <div class="item__content">
                    <h1 class="item__name">букеты</h1>
                    <p class="item__price">от 3 420 рублей</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Монобукеты', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='Монобукеты'}">
                <img class="item__img" src="../images/main-categories/mon.png">
                
                <div class="item__content">
                    <h1 class="item__name">Монобукеты</h1>
                    <p class="item__price">от 3 500 рублей</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Цветы поштучно', 'Хризантема')"
                :class="{activeCategory: currentFlower=='Хризантема'}">
                <img class="item__img" src="../images/main-categories/chris.png">

                <div class="item__content">
                    <h1 class="item__name">хризантемы</h1>
                    <p class="item__price">от 177 рублей</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Цветы поштучно', 'Роза')"
                :class="{activeCategory: currentFlower=='Роза'}">
                <img class="item__img" src="../images/main-categories/ros.png">
                
                <div class="item__content">
                    <h1 class="item__name">Розы</h1>
                    <p class="item__price">от 209 рублей</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Цветы поштучно', '')"
                :class="{activeCategory: currentFlower=='Цветы'}">
                <img class="item__img" src="../images/main-categories/one.png">

                <div class="item__content">
                    <h1 class="item__name">цветы поштучно</h1>
                    <p class="item__price">от 90 рублей</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Свадебные букеты', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='Свадебные букеты'}">
                <img class="item__img" src="../images/main-categories/wed.png">
                
                <div class="item__content">
                    <h1 class="item__name">Свадебные букеты</h1>
                    <p class="item__price">скоро будут!</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Открытки', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='Открытки'}">
                <img class="item__img" src="../images/main-categories/car.png">

                <div class="item__content">
                    <h1 class="item__name">Открытки</h1>
                    <p class="item__price">от 350 рублей</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Мягкие игрушки', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='Мягкие игрушки'}">
                <img class="item__img" src="../images/main-categories/toy.png">
                
                <div class="item__content">
                    <h1 class="item__name">Мягкие игрушки</h1>
                    <p class="item__price">От 440 р</p>
                </div>
            </div>

            <div 
                class="categories__item item" 
                @click="redirectToCatalog('Букеты до 3000', '')"
                :class="{activeCategory: SELECTED_CATEGORY=='Букеты до 3000'}">
                <img class="item__img" src="../images/main-categories/3000.png">
                
                <div class="item__content">
                    <h1 class="item__name">Букеты <br><span class="item__name_fsz">до</span> <span class="item__name_price">3000</span></h1>
                </div>
            </div>

            <!-- <div class="categories__item item" @click="redirectToCatalog('Новогодняя коллекция', '')">
                <img class="item__img" src="../images/main-categories/new-year.png">
                
                <div class="item__content">
                    <h1 class="item__name">Новогодняя коллекция</h1>
                    <p class="item__price">от 2510 р</p>
                    <span class="item__show">смотреть</span>
                </div>
            </div> -->

        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            currentFlower: ''
        }
    },
    computed: {
        ...mapGetters([
            'SELECTED_CATEGORY'
        ])
    },
    methods: {
        ...mapActions([
            'RESET_PAGE_NUMBER',
            'GET_CATEGORY_VALUE',
            'SELECT_CATEGORY',
            'GET_PRODUCTS',
            'RESET_SEARCH_VALUE'
        ]),
        redirectToCatalog(category, subcategory){
            // this.$emit('scrollToBlock')
            window.scrollTo(0, 1950)
            this.currentFlower = subcategory
            if (category == 'Цветы поштучно' && subcategory=='') {
                this.currentFlower = 'Цветы'
            }
            this.RESET_SEARCH_VALUE(); 
            this.GET_CATEGORY_VALUE(subcategory)
            this.RESET_PAGE_NUMBER()
            this.SELECT_CATEGORY(category);
            this.GET_PRODUCTS();
            this.$router.push({query: {'category': category, 'page': 1}})
        }
    },
}
</script>

<style scoped>
    .main-categories__heading{
        margin-bottom: 70px;
        font-size: 36px;
        text-align: left;
    }
    .main-categories__inner{
        display: flex;
        gap: 16px;
        overflow-x: scroll;
        cursor: grab;
        padding-bottom: 15px;
    }
    .main-categories__inner::-webkit-scrollbar {
        width: 8px;
        height: 10px;        
    }
    .main-categories__inner::-webkit-scrollbar-track {
        background: #ECDEF6; 
        border-radius: 50px;       
    }
    .main-categories__inner::-webkit-scrollbar-thumb {
        background-color: #9E72C2;    
        border-radius: 20px;       
    }
    .categories__item{
        /* width: 350px; */
        position: relative;
        display: flex;
        align-items: center;
        transition: all linear .2s;
        cursor: grab;
    }
    .categories__item:hover{
        transform: scale(.95);
    }
    .item__img{
        width: 100%;
        height: 100%;
        min-width: 350px;
        max-width: 350px;
    }
    .item__content{
        position: absolute;
        left: 15px;
        color: #fff;
    }
    .item__content{
        font-family: 'Playfair Display', serif;
    }
    .item__content_march-promo{
        position: absolute;
        font-family: 'Playfair Display', serif;
        color: #fff;
        text-transform: uppercase;
        left: 30px;
        bottom: 40px;
    }
    .item__name{
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: uppercase;
        max-width: 260px;
    }
    .item__price{
        font-size: 18px;
        margin-bottom: 15px;
    }
    .item__show{
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .main-categories__heading-mob{
        display: none;
    }
    .item__name_fsz{
        font-size: 19px;
    }
    .item__name_price{
        font-size: 48px;
        display: inline-block;
        margin-top: -15px;
    }
    .activeCategory{
        opacity: .5;
    }
    @media (max-width: 768px) {
        .main-categories__heading{
            display: none;
        }
        .item__name_price{
            font-size: inherit;
        }
        .main-categories__heading-mob{
            display: block;
            margin-bottom: 30px;
            font-size: 30px;
            text-align: left;
        }
        .main-categories__inner{
            grid-template-columns: repeat(1, 1fr);
            gap: 5px;
        }
        .item__content{
            left: 20px;
        }
        .item__name{
            font-size: 22px;
            max-width: 150px;
            margin-bottom: 5px;
        }
        .item__price{
            font-size: 14px;
            margin-bottom: 5px;
        }
        .item__show{
            font-size: 10px;
        }
        .item__name_fsz{
            font-size: 12px;
        }
        .item__img{
            width: 100%;
            min-width: 220px;
            max-width: 220px;
        }
    }
</style>